import { TitleContainer } from "../../common/title-container";
import {
  OurStoryContainer,
  OurStoryImage,
  OurStoryParagraph,
} from "./our-story.style";
import GroupPicture from "./group.jpeg";

export const OurStory = () => {
  return (
    <OurStoryContainer>
      <TitleContainer
        titleStart="Our"
        titleEmphasis="Story"
        description={<>How cradle came to be...</>}
      />
      <OurStoryImage src={GroupPicture} />
      <OurStoryParagraph>
        We met while completing our doctoral training in clinical psychology at
        Trinity College Dublin and have been friends ever since. Cradle was
        conceived through many conversations over the years - some over a glass
        of wine, some over a string of WhatsApp voicenotes and texts.
      </OurStoryParagraph>
      <OurStoryParagraph>
        When Marese became pregnant with her first child, it got us thinking
        about what supports are available during the complex transition to
        parenthood, that time when you go from being just ‘you’ to ‘you as a
        parent’. Despite having a world of knowledge about psychology, wellbeing
        and mental health, it was still a challenge for Marese to make sense of
        what she was feeling and what she needed emotionally during this time.
        This emotional complexity became even more apparent after her beautiful
        baby was born. During this time it became more clear to us than ever how
        valuable the strategies and psychological theories that we use in our
        everyday practice could be both during the perinatal period and beyond.
        Having a space to talk through and reflect on these experiences was
        essential. For Marese, that space became Johanna’s kitchen!
      </OurStoryParagraph>
      <OurStoryParagraph>
        We know how important it is to identify what nourishes and supports you
        emotionally as you embark on your transition to parenthood. The first
        step to becoming a ‘good enough parent’ is knowing how to take care of
        yourself. This has been proven across the research literature on
        parenting and infant mental health. As Dr. Gabor Mate, expert in
        childhood development, once said, “the primary task of parenting is
        self-parenting, taking care of ourselves”.
      </OurStoryParagraph>
      <OurStoryParagraph>
        We developed our perinatal workshops because we want to give soon-to-be
        and new parents the opportunity to truly engage in this experience at an
        emotional level, with the trusted support and guidance of experienced
        clinical psychologists. Our workshops are educational and experiential,
        meaning you will learn a lot, but will also be guided therapeutically
        through supportive exercises. We want attendees to feel that our
        workshops are a self-compassionate and self-caring activity during your
        pregnancy journey. You will also meet, and have the opportunity to swap
        stories and learn from other expectant or new parents.
      </OurStoryParagraph>
      <OurStoryParagraph>
        Our consultation clinic is available to expectant and new parents as
        well as parents of young children (under 5). This service offers
        one-to-one sessions with experienced senior clinical psychologists,
        focused on helping you to explore and work through difficulties you may
        be experiencing yourself, or psychological problems that your child may
        be experiencing. You can read more about this service by clicking the
        Consultation Clinic tab.
      </OurStoryParagraph>
      <OurStoryParagraph>
        Our workshops and consultation clinic centre around the everyday
        experience of pregnancy and wider parenthood, framed through the lens of
        clinical psychology. Our service does not aim to provide specialist
        clinical or mental health support for those experiencing or at risk of
        experiencing perinatal mental health difficulties. If you are reading
        this and you think that you would benefit from more specialist support
        we would encourage you to speak with your GP or maternity hospital.
        Those services are available and are a crucial part of perinatal health
        services.
      </OurStoryParagraph>
    </OurStoryContainer>
  );
};
