import { RegisterButton } from "../../common/register/register.styles";
import {
  EventsContainer,
  HeroEventBlock,
  HeroEventBlockDivider,
  HeroEventBlockSubDivider,
} from "./workshop-upsell.style";
import poster from "./poster.png"

const video = require("./intro.mp4");

export const WorkshopUpsell = () => {
  return (
    <EventsContainer>
      <video width="320" height="240" controls poster={poster}>
        <source src={video} type="video/mp4" />
      </video>
      <HeroEventBlock>
        <HeroEventBlockDivider>
          <HeroEventBlockSubDivider>
            <h2>Preparing for Parenthood Workshop (Recording)</h2>
            <span>Unlimited access</span>
          </HeroEventBlockSubDivider>
          <RegisterButton
            as="a"
            mode="primary"
            style={{
              display: "inline-block",
            }}
            target="_blank"
            rel="noreferrer"
            href="https://buy.stripe.com/eVa8zC0WV5924ve5km"
          >
            Buy Now
          </RegisterButton>
        </HeroEventBlockDivider>
      </HeroEventBlock>
    </EventsContainer>
  );
};
