import { FC } from "react";
import { TitleContainer } from "../../common/title-container";
import {
  ProblemCardContainer,
  ProblemCardDescription,
  ProblemCardList,
  ProblemCardTitle,
  ProblemOuterContainer,
  ProblemContainer,
} from "./problem.style";
import EmotionalChangeIcon from "./emotional-change.png";
import FocusIcon from "./focus.png";
import EarlySupportIcon from "./early-support.png";
import { ReactComponent as RainDecal } from "./rain.svg";
import { ReactComponent as Squiggle } from "./squiggle.svg";

interface IProblemCard {
  title: string;
  description: string;
  icon: React.ReactNode;
}

export const ProblemCard: FC<IProblemCard> = ({ icon, title, description }) => {
  return (
    <ProblemCardContainer>
      <ProblemCardTitle>
        {icon}
        {title}
      </ProblemCardTitle>
      <ProblemCardDescription>{description}</ProblemCardDescription>
    </ProblemCardContainer>
  );
};

export const Problem = () => {
  return (
    <ProblemOuterContainer id="problem">
      <ProblemContainer>
        <TitleContainer
          titleStart="The"
          titleEmphasis="Problem"
          description={
            <>
              Why we think our service is essential for you on your parenting
              journey.
            </>
          }
        />
        <ProblemCardList>
          <RainDecal />
          <ProblemCard
            icon={<img src={EmotionalChangeIcon} alt="Logo" />}
            title="Emotional Change"
            description="Emotional changes are expected during pregnancy and parenthood. This is normal."
          />
          <ProblemCard
            icon={<img src={FocusIcon} alt="Logo" />}
            title="Focus"
            description="Many supports  are  focused on the physical transition to parenthood, with less focus on the mental wellbeing of parents and young children."
          />
          <ProblemCard
            icon={<img src={EarlySupportIcon} alt="Logo" />}
            title="Accessibility"
            description="We believe that psychoeducation is crucial and should be available to all parents."
          />
          <Squiggle />
        </ProblemCardList>
      </ProblemContainer>
    </ProblemOuterContainer>
  );
};
