import styled from 'styled-components'
import { breakPoint } from '../../styles'

interface ISubText {
  align: 'right' | 'left'
}

interface IRegisterButton {
  mode: 'primary' | 'secondary'
}

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-bottom: 66px;

  ${breakPoint(
    `
    width: 100%;
  `,
    'small',
  )}

  ${breakPoint(
    `
    margin: 0 auto;
    padding-bottom: 33px;
  `,
    'large',
  )}
`

export const RegisterForm = styled.form`
  display: flex;
  background: white;
  padding: 8px;
  border-radius: 4px;
  gap: 4px;
  border: 1px solid #dadada;
`

export const RegisterInput = styled.input`
  font-size: 16px;
  width: 100%;
  padding-left: 12px;
`

export const RegisterButton = styled.button<IRegisterButton>`
  flex-shrink: 0;
  outline: none;
  padding: 20px;
  background: ${({mode}) => mode === "primary" ? "#ea542b" : "transparent"};
  border: 1px solid ${({mode}) => mode === "primary" ? "transparent" : "#ea542b"};
  font-size: 16px;
  font-weight: bold;
  color: ${({mode}) => mode === "primary" ? "white" : "#ea542b"};;
  border-radius: 4px;
`

export const RegisterSubText = styled.span<ISubText>`
  display: block;
  text-align: ${({ align }) => align};
  font-size: 16px;
  padding-top: 18px;
  color: #726b6b;
  line-height: 24px;

  a {
    text-decoration: underline;
  }
`
