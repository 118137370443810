import styled from "styled-components";
import { breakPoint } from "../../styles";

export const TestimonialOuterContainer = styled.div`
  background: rgba(255, 255, 255, 1);
`;

export const TestimonialContainer = styled.div`
  padding: 80px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  scroll-margin-top: 40px;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    "large"
  )}
`;