import styled from 'styled-components'
import { breakPoint } from '../../styles'

interface IMeetTheTeamBioContainer {
  reverse?: boolean
}

export const MeetTheTeamContainer = styled.div`
  padding: 80px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    'large',
  )}
`

export const MeetTheTeamBioContainer = styled.div<IMeetTheTeamBioContainer>`
  display: flex;
  ${({ reverse }) => {
    if (reverse) {
      return 'flex-direction: row-reverse;'
    } else {
      return 'flex-direction: row;'
    }
  }}
  font-size: 18px;
  line-height: 34px;
  gap: 32px;
  align-items: center;
  color: rgba(100, 100, 100, 1);
  padding-bottom: 80px;

  > img {
    width: 40%;
  }

  ${breakPoint(
    `
    flex-direction: column;

    > img {
      width: 90%;
    }
  `,
    'small',
  )}
`
