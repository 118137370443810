import styled from 'styled-components'
import { breakPoint } from '../../styles'

export const HeroContainer = styled.div`
  background: #f9f4ea;
  overflow: hidden;
`

export const HeroContentWrapper = styled.div`
  padding: 100px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;

  ${breakPoint(
    `
    padding: 32px;
  `,
    'large',
  )}

  p {
    margin-bottom: 60px;
  }

  ${breakPoint(
    `
    p {
      margin-bottom: 40px;
      color: #5b5b5b;
    }
  `,
    'large',
  )}
`

export const HeroInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 668px;

  margin-top: 64px;
  z-index: 1;

  ${breakPoint(
    `
    width: 100%;
    max-width: 100%;
  `,
    'large',
  )}
`

export const HeroInfoContainerTitle = styled.span`
  font-weight: 500;
  font-size: 64px;

  ${breakPoint(
    `
    text-align: center;
    font-size: 34px;
  `,
    'small',
  )}

  ${breakPoint(
    `
    text-align: center;
  `,
    'large',
  )}
`

export const HeroInfoContainerTitleEmphasis = styled.span`
  position: relative;
  display: inline-block;
  font-weight: bold;
`

export const HeroInfoDescription = styled.p`
  font-size: 32px;
  font-weight: 200;
  line-height: 50px;
  margin: 44px 0 110px 0;
  color: #9b9b9b;

  ${breakPoint(
    `
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 60px;
  `,
    'large',
  )}
`

export const HeroUnderlineContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${breakPoint(
    `
    svg {
      width: 100px;
    }
  `,
    'large',
  )}
`

export const HeroInfoDescriptionEmphasis = styled.span`
  display: inline-block;
  font-weight: bold;
  position: relative;
`

export const HeroWomanContainer = styled.div`
  position: absolute;
  top: -28px;
  right: -180px;
  z-index: 0;
  width: 600px;

  ${breakPoint(
    `
    display: none;
  `,
    'large',
  )}

  > svg {
    width: 90%;
  }
`

export const HeroShadowWomanContainer = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 0;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100%;

  img {
    opacity: 0.2;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: scale(1.1) translate(-50%, -50%);
    transform-origin: center;
  }

  ${breakPoint(
    `
    display: block;
  `,
    'large',
  )}
`