import { useMemo } from 'react'
import {
  RegisterButton,
  RegisterContainer,
  RegisterForm,
  RegisterInput,
  RegisterSubText,
} from './register.styles'

export const Register = () => {
  const hasRegistered = useMemo(() => {
    return window.location.pathname === '/success'
  }, [])

  return (
    <RegisterContainer>
      {!hasRegistered && (
        <>
          <RegisterForm name="register" method="POST" action="/success">
            <input type="hidden" name="form-name" value="register" />
            <RegisterInput
              name="email"
              placeholder="Email Address"
              type="email"
            />
            <RegisterButton mode="primary" type="submit">
              Keep in touch
            </RegisterButton>
          </RegisterForm>
        </>
      )}
      {hasRegistered && (
        <RegisterSubText align="left">
          Thank you for registering your interest! If your free guide did not
          appear please check your spam folder, otherwise email us at{' '}
          <a href="mailto:hello@cradlepsychology.com">
            hello@cradlepsychology.com
          </a>
        </RegisterSubText>
      )}
    </RegisterContainer>
  )
}
