import { Navigation } from '../../common/navigation'
import { FooterContainer, FooterWrapper } from './footer.style'

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Navigation />
      </FooterWrapper>
    </FooterContainer>
  )
}
