import { FC } from 'react'
import { TitleContainer } from '../../common/title-container'
import {
  MeetTheTeamBioContainer,
  MeetTheTeamContainer,
} from './meet-the-team.style'
import JohannaHeadshot from './johanna.png'
import MareseHeadshot from './marese.png'

interface IMeetTheTeamBio {
  headshot: string
  description: string
  reverse?: boolean
}

const MeetTheTeamBio: FC<IMeetTheTeamBio> = ({
  headshot,
  description,
  reverse,
}) => {
  return (
    <MeetTheTeamBioContainer reverse={reverse}>
      <img src={headshot} alt="headshot" />
      {description}
    </MeetTheTeamBioContainer>
  )
}

export const MeetTheTeam = () => {
  return (
    <MeetTheTeamContainer id="about">
      <TitleContainer
        titleStart="Meet the"
        titleEmphasis="Team"
        description={
          <>We’ll be supporting you</>
        }
      />
      <MeetTheTeamBio
        headshot={JohannaHeadshot}
        description="Dr. Johanna Clancy, B.A. (Hons.), M.Sc., D.Clin.Psych., qualified with a doctorate in clinical psychology from Trinity College Dublin in 2019. She has worked across various clinical settings, including in Adult Mental Health Services, Children and Adult Disability Services, Child and Adolescent Psychology Services, as well as in a children’s hospital. In addition to her work with Cradle, Johanna is a Senior Clinical Psychologist, specialising in paediatrics, within the Irish Health Service. Johanna has a special interest in childhood mental health, family dynamics and parenting. She practices adapting an integrative approach, which draws on attachment-based, psychodynamic and cognitive-behavioural models. Johanna lives in Dublin with her partner and is expecting a baby in late 2023. Her goldendoodle, Frank, features on our Cradle social media from time to time!"
      />
      <MeetTheTeamBio
        reverse
        headshot={MareseHeadshot}
        description="Dr. Marese McDonnell (B.A., M.Sc., D.Clin.Psych.) qualified as a clinical psychologist in 2019 having completed her doctoral studies in Trinity College Dublin. She has experience working across a range of settings including child and adult mental health services, primary care psychology, child and adult disability services, and hospital psychology services. Marese currently works part-time as a senior clinical psychologist within the public health sector. In her work she draws mainly upon the therapeutic approaches of Acceptance and Commitment Therapy and Compassion Focused Therapy.  Marese lives with her husband and young daughter and son."
      />
    </MeetTheTeamContainer>
  )
}
