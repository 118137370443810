import styled from "styled-components";
import { breakPoint } from "../../styles";

export const ProblemOuterContainer = styled.div`
  background: rgba(249, 244, 234, 0.4);
`;

export const ProblemContainer = styled.div`
  padding: 80px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  scroll-margin-top: 40px;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    "large"
  )}
`;

export const ProblemCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 32%;
  min-width: 300px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  z-index: 1;
  background: white;

  ${breakPoint(
    `
    width: 100%;
  `,
    "small"
  )}
`;

export const ProblemCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;

  img {
    width: 50px;
  }
`;

export const ProblemCardDescription = styled.div`
  font-size: 16px;
  line-height: 32px;
  font-weight: normal;
  text-align: center;
`;

export const ProblemCardList = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  ${breakPoint(
    `
    flex-direction: column;
    gap: 20px;
  `,
    "small"
  )}

  ${breakPoint(
    `
    svg {
        display: none;
    }
  `,
    "large"
  )}

  svg:first-child {
    position: absolute;
    bottom: -50%;
    left: -100px;
    z-index: -1;
  }

  svg:last-child {
    position: absolute;
    top: -40%;
    right: -100px;
    z-index: -1;
  }
`;
