import { Register } from '../../common/register'
import {
  BackgroundImg,
  LargeRegisterContainer,
  LargeRegisterTitle,
  LargeRegisterWrapper,
  LargeRegisterSubtext
} from './large-register.style'
import Background from './background.svg'

export const LargeRegister = () => {
  return (
    <LargeRegisterContainer id="register">
      <BackgroundImg src={Background} />
      <LargeRegisterWrapper>
        <LargeRegisterTitle>Want to keep in touch?</LargeRegisterTitle>
        <LargeRegisterSubtext>
          Join our mailing list to receive a free helpful guide on tools to help
          you during your transition to parenthood!
        </LargeRegisterSubtext>
        <Register />
      </LargeRegisterWrapper>
    </LargeRegisterContainer>
  )
}
