import styled from 'styled-components'
import { breakPoint } from '../../styles'

export const PrivacyPolicyContainer = styled.div`
  padding: 80px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    'large',
  )}

  line-height: 30px;

  span {
    margin-bottom: 40px;
  }

  h1 {
    margin: 20px 0;
    font-weight: bold;
    font-size: 24px;
  }

  h2 {
    margin: 20px 0;
    font-weight: bold;
    font-size: 20px;
  }

  h3 {
    margin: 10px 0;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
  }

  p {
    margin: 10px 0;
  }

  ul {
    padding: 0 0 0 20px;

    li {
        list-style: disc;
    }
  }
`
