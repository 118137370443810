import { FC } from 'react'
import {
  TitleContainerDescription,
  TitleContainerTitle,
  TitleContainerTitleEmphasis,
  TitleContainerUnderlineWrapper,
  TitleContainerWrapper,
} from './title-container.style'
import { ReactComponent as Underline } from './underline.svg'

interface ITitleContainer {
  titleStart: string
  titleEmphasis?: string
  description: React.ReactNode
  children?: React.ReactNode
}

export const TitleContainer: FC<ITitleContainer> = ({
  titleStart,
  titleEmphasis,
  description,
  children,
}) => {
  return (
    <TitleContainerWrapper>
      <TitleContainerTitle>
        {titleStart}{' '}
        {titleEmphasis && (
          <TitleContainerTitleEmphasis>
            {titleEmphasis}
            <TitleContainerUnderlineWrapper>
              <Underline />
            </TitleContainerUnderlineWrapper>
          </TitleContainerTitleEmphasis>
        )}
      </TitleContainerTitle>
      <TitleContainerDescription>{description}</TitleContainerDescription>
      {children}
    </TitleContainerWrapper>
  )
}
