import {
  HeroContainer,
  HeroContentWrapper,
  HeroInfoDescriptionEmphasis,
  HeroWomanContainer,
  HeroShadowWomanContainer,
} from "./hero.style";
import { ReactComponent as Woman } from "./woman.svg";
import CradleScribble from "./svgexport-7.png";
import { TitleContainer } from "../../common/title-container/title-container";

export const Hero = () => {
  return (
    <HeroContainer>
      <HeroContentWrapper>
        <TitleContainer
          titleStart="We are"
          titleEmphasis="Cradle"
          description={
            <>
              Psychology supports, focused on parental wellbeing, uniquely
              developed and delivered by{" " }
              <HeroInfoDescriptionEmphasis>
                clinical psychologists.
              </HeroInfoDescriptionEmphasis>{" "}We want to
              share our knowledge to give you the best possible parenting
              journey.
            </>
          }
        />
        <HeroWomanContainer>
          <Woman />
        </HeroWomanContainer>
        <HeroShadowWomanContainer>
          <img src={CradleScribble} alt="Pregnant woman" />
        </HeroShadowWomanContainer>
      </HeroContentWrapper>
    </HeroContainer>
  );
};
