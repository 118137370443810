import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactGA from 'react-ga';

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

let currentPathname: string = "";

export const AnalyticsRouteTracker = withRouter((props: any) => {
    const { router } = props;
    const {  pathname } = router.location;
    useEffect(() => {
        if (pathname !== currentPathname) {
            currentPathname = pathname;
            ReactGA.set({ page: pathname });
            ReactGA.pageview(pathname);
            return;
        }
    }, [pathname])

    return <div></div>
})
