import { FC } from 'react'
import { TitleContainer } from '../../common/title-container'
import {
  OurApproachCardContainer,
  OurApproachCardDescription,
  OurApproachCardList,
  OurApproachCardTitle,
  OurApproachContainer,
  OurApproachContainerWrapper,
} from './our-approach.style'
import one from './1.png'
import two from './2.png'
import three from './3.png'
import four from './4.png'

interface IOurApproachCard {
  title: string
  description: string
  icon: React.ReactNode
}

const OurApproachCard: FC<IOurApproachCard> = ({
  icon,
  title,
  description,
}) => {
  return (
    <OurApproachCardContainer>
      <OurApproachCardTitle>
        {title}
        {icon}
      </OurApproachCardTitle>
      <OurApproachCardDescription>{description}</OurApproachCardDescription>
    </OurApproachCardContainer>
  )
}

export const OurApproach = () => {
  return (
    <OurApproachContainer id="our-approach">
      <OurApproachContainerWrapper>
        <TitleContainer
          titleStart="Our"
          titleEmphasis="Approach"
          description={<>What our workshop offers</>}
        />
        <OurApproachCardList>
          <OurApproachCard
            icon={<img src={one} alt="Logo" />}
            title="Learn"
            description="We’ll provide you with comprehensive theory-driven information about pregnancy and parenthood. You will develop an understanding about how psychology can help to support you, your relationships, and your baby."
          />
          <OurApproachCard
            icon={<img src={two} alt="Logo" />}
            title="Reflect"
            description="A space to reflect on and talk about your individual experience of pregnancy and journey towards parenthood. Life often moves so fast we don’t always take the time to notice and acknowledge what we’re feeling. This is especially important during transition periods like becoming a parent."
          />
          <OurApproachCard
            icon={<img src={three} alt="Logo" />}
            title="Share"
            description="Pregnancy and new parenthood, for some people, can be a lonely experience. During our workshops you will get to connect with others. You can listen and share experiences in a way that feels comfortable for you. This is safe and therapeutic space provided by expert clinicians."
          />
          <OurApproachCard
            icon={<img src={four} alt="Logo" />}
            title="Apply"
            description="We provide you with practical advice to help you manage any thoughts or feelings you might have about pregnancy, birth, and new parenthood. We will help you to figure out what tools work best for you and will practice different ideas in the session. You will go home with a toolkit of strategies rooted in psychological theory."
          />
        </OurApproachCardList>
      </OurApproachContainerWrapper>
    </OurApproachContainer>
  )
}
