import React from "react";
import { TitleContainer } from "../../common/title-container";
import { ClinicContainer, ClinicContainerWrapper } from "./clinic.style";

class Calendly extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head && head.appendChild(script);
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  render(){
    return (
      <div>
        <div id="schedule_form">
          <div 
            className="calendly-inline-widget"
            data-url="https://calendly.com/cradlepsychology/consultation-clinic?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=ff9300"
            style={{ minWidth: '320px', height: '800px' }} />
        </div>
      </div>
    );
  }
}

export const Clinic = () => {
  return (
    <ClinicContainer id="our-approach">
      <ClinicContainerWrapper>
        <TitleContainer
          titleStart="Consultation"
          titleEmphasis="Clinic"
          description={
            <>
              Online parent consultation clinic to seek advice from a Chartered
              Senior Clinical Psychologist
            </>
          }
        />
        <p className="first-paragraph">
          Book a space for our online parent consultation clinic to seek advice
          from a Chartered Senior Clinical Psychologist. We provide information
          and advice specific to&nbsp;<strong>parents</strong>&nbsp;
          <strong>and expectant parents.&nbsp;</strong>You can attend a
          consultation to seek advice about your own experiencing of parenting
          (for example: pregnancy anxiety, emotional preparation for parenthood,
          parent/child attachment, parent identity, returning to work, general
          mental health) or about a concern relating to a child aged
          between&nbsp;<strong>0-5 years&nbsp;</strong>(for example: feeding,
          sleeping, toileting, mood, emotional regulation).
          <br />
          <Calendly/>
          <br />A few important notes:
        </p>
        
        <ul>
          <li>
            We do not meet directly with your child as part of consultation
            clinics. We ask that children are not present on screen. Information
            is provided to parents/guardians only.&nbsp;
          </li>
          <li>
            We will provide advice and information to the best of our clinical
            knowledge and within our competency area of professional clinical
            psychology. Where we believe that further specialist advice is
            needed we will provide signposting information for other services,
            however it is the parents&#39; responsibility to follow up on this
            advice independently.
          </li>
          <li>
            Where possible it is helpful if both parents attend consultations
            (however, we appreciate this is not always feasible).
          </li>
          <li>
            As clinical psychologists are are mandated reporters under
            Children&#39;s First: National Guidance for the Protection and
            Welfare of Children (2017). This means that where we become
            concerned about any potential risk posed to a child, either
            currently or in the past, we are required by law to report these
            concerns to the Child and Family Agency (TUSLA) with all relevant
            information we are aware of.&nbsp;
          </li>
          <li>
            In order to maintain the safety of all those using our services, we
            request emergency contact information at booking. Provision of this
            information serves as consent for us to make contact with emergency
            contacts in the case that we become aware of a potential medical or
            welfare emergency during a consultation.
          </li>
          <li>
            As is standard procedure in all clinical services, we maintain brief
            clinical notes describing the interaction and advice provided. As
            per GDPR guidelines, this information is stored electronically in
            password-protected files and on encrypted, secure drives.&nbsp;
          </li>
        </ul>
      </ClinicContainerWrapper>
    </ClinicContainer>
  );
};
