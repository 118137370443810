import styled from 'styled-components'
import { breakPoint } from '../../styles'
import { HashLink } from 'react-router-hash-link';

interface IAnchor {
  takeover?: boolean
}

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NavigationAnchorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

export const NavigationAnchor = styled(HashLink)<IAnchor>`
  text-decoration: none;
  color: #2a3628;

  ${breakPoint(
    `
    display: none;
  `,
    'small',
  )}
`

export const NavigationAnchorPure = styled.a`
  text-decoration: none;
  color: #2a3628;

  ${breakPoint(
    `
    display: none;
  `,
    'small',
  )}
`

export const NavigationAnchorTakeover = styled(HashLink)<IAnchor>`
  text-decoration: none;
  color: white;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  display: block;
  text-transform: capitalize;
`

export const NavigationAnchorTakeoverPure = styled.a<IAnchor>`
  text-decoration: none;
  color: white;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  display: block;
  text-transform: capitalize;
`

export const HamburgerMenu = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 40px;
  height: 30px;

  outline: none;

  ${breakPoint(
    `
    display: flex;
  `,
    'small',
  )}
`

export const HamburgerLayer = styled.div`
  width: 100%;
  height: 33.333%;

  outline: none;
  background: black;
`

export const TakeoverMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 100px;
  gap: 32px;

  z-index: 9999;

  background: rgba(0, 0, 0, 0.8);
`

export const TakeoverMenuCloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  outline: none;
`
