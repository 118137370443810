import styled from 'styled-components'

export const FooterContainer = styled.div`
  background: #f9f4ea;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
`

export const FooterWrapper = styled.div`
  max-width: 1070px;
  margin: 0 auto;
`
