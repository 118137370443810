import styled from 'styled-components'
import { breakPoint } from '../../styles'

export const TitleContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 668px;

  margin-top: 34px;
  z-index: 1;

  ${breakPoint(
    `
    width: 100%;
    max-width: 100%;
  `,
    'large',
  )}
`

export const TitleContainerTitle = styled.span`
  font-weight: 500;
  font-size: 64px;

  ${breakPoint(
    `
    text-align: center;
    font-size: 34px;
  `,
    'small',
  )}

  ${breakPoint(
    `
    text-align: center;
  `,
    'large',
  )}
`

export const TitleContainerTitleEmphasis = styled.span`
  position: relative;
  display: inline-block;
  font-weight: bold;
`

export const TitleContainerDescription = styled.p`
  font-size: 24px;
  font-weight: 200;
  line-height: 40px;
  margin: 44px 0 60px 0;
  color: #9b9b9b;

  ${breakPoint(
    `
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  `,
    'large',
  )}
`

export const TitleContainerUnderlineWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${breakPoint(
    `
    svg {
      width: 100px;
    }
  `,
    'large',
  )}
`