import { useEffect, useState } from "react";
import { TitleContainer } from "../../common/title-container";
import "./styles.css";
import {
  TestimonialContainer,
  TestimonialOuterContainer,
} from "./testimonials.style";
import quote from './quote.png'


const testimonialData = [
  {
    text: "I loved this workshop and wishes it was something i had when i became a mum on my first. It was a great space, a relaxing atmosphere and the therapists were wonderful and very open. I would 100% recommend it to any new mums.",
    caption: "Workshop participant, March 2023",
  },
  {
    text: "It was a lovely and supportive workshop. It felt like a very open and supportive space. Mental health awareness is so important for new mothers and I am greatful for the advice and support offered.",
    caption: "Workshop participant, March 2023",
  },
  {
    text: "Overall really enjoyed the workshop & I thought it was very useful. Everyone should have access to it during their pregnancy!",
    caption: "Workshop participant, March 2023",
  },
  {
    text: "I joined this workshop feeling anxious and scared also feeling that I'm alone in experiencing these emotions. Every slide felt relatable and just hearing Johanna and Marese talking me through every single emotion with kindness...",
    caption: "Workshop participant, March 2023",
  },
];

let timer: NodeJS.Timer;

export const Testimonials = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false);

  useEffect(() => {
    if (isInteracting) return;

    timer = setInterval(() => {
      let newVal = selectedTestimonial + 1;

      if (newVal > testimonialData.length - 1) {
        newVal = 0;
      }

      setSelectedTestimonial(newVal);
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [selectedTestimonial, isInteracting]);

  return (
    <TestimonialOuterContainer id="testimonials">
      <TestimonialContainer>
        <TitleContainer
          titleStart=""
          titleEmphasis="Testimonials"
          description={
            <>
              Some kind words from our community.
            </>
          }
        />
        <div className="testimonials-section">
          <div className="viewport">
            <div
              className="slider__inner"
              style={{
                left: `-${selectedTestimonial * 100}%`,
                width: `${testimonialData.length * 100}%`,
              }}
            >
              {testimonialData.map((ctl, idx) => (
                <div className="slider__contents" key={`slider__contents_${idx}`}>
                  <img alt="quote icon" src={quote} style={{width: "50px", marginBottom: "20px"}} />
                  <p className="slider__txt">{ctl.text}</p>
                  <h2 className="slider__caption">{ctl.caption}</h2>
                </div>
              ))}
            </div>
          </div>
          <div className="testimonials-section__nav">
            {testimonialData.map((_, idx) => (
              <input
                key={`testimonials-section__nav_${idx}`}
                onClick={() => {
                  clearInterval(timer);
                  setIsInteracting(true);
                  setSelectedTestimonial(idx);
                }}
                type="radio"
                name="slider"
                title="slide1"
                readOnly
                checked={selectedTestimonial === idx}
                className="slider__nav"
              />
            ))}
          </div>
        </div>
      </TestimonialContainer>
    </TestimonialOuterContainer>
  );
};
