import styled from "styled-components";
import { breakPoint } from "../../styles";

export const EventsContainer = styled.div`
  padding: 80px 0;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  scroll-margin-top: 0;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    "large"
  )}

  video {
    width: 100%;
    height: auto;
  }

  video::-webkit-media-controls-panel {
   background-image: none !important;
   filter: brightness(0.4);
}
`;

export const HeroEventBlock = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  ${breakPoint(
    `
    max-width: initial;
  `,
    "large"
  )}

  ${breakPoint(
    `
    a {
      width: 100%;
      text-align: center;
    }
  `,
    "small"
  )}
`;

export const HeroEventBlockTitle = styled.h1`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 14px;
  ${breakPoint(
    `
    text-align: center;
  `,
    "small"
  )}
`;

export const HeroEventBlockDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;

  > span {
    flex-shrink: 0;
  }

  ${breakPoint(
    `
    flex-direction: column;
    gap: 20px;
  `,
    "small"
  )}
`;

export const HeroEventBlockSubDivider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding-left: 80px;

  h2 {
    font-size: 18px;
  }

  span {
    opacity: 0.6;
  }

  ${breakPoint(
    `
    text-align: center;
    padding-left: 0;
  `,
    "small"
  )}
`;
