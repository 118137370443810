import {
  NavigationContainer,
  NavigationAnchor,
  NavigationAnchorContainer,
  HamburgerMenu,
  HamburgerLayer,
  TakeoverMenu,
  TakeoverMenuCloseButton,
  NavigationAnchorTakeover,
  NavigationAnchorPure,
  NavigationAnchorTakeoverPure,
} from './navigation.style'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as Close } from './close.svg'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const Navigation = () => {
  const [isMenuShown, setIsMenuShown] = useState(false)

  return (
    <NavigationContainer>
      <Link to="/">
        <Logo />
      </Link>
      <NavigationAnchorContainer>
        <NavigationAnchor to="/about">our story</NavigationAnchor>
        <NavigationAnchor to="/clinic">consultation clinic</NavigationAnchor>
        <NavigationAnchor to="/workshops">workshops</NavigationAnchor>
        <NavigationAnchor to="/privacypolicy">privacy policy</NavigationAnchor>
        <NavigationAnchorPure href="mailto:hello@cradlepsychology.com">contact</NavigationAnchorPure>
        <HamburgerMenu onClick={() => setIsMenuShown(!isMenuShown)}>
          <HamburgerLayer />
          <HamburgerLayer />
          <HamburgerLayer />
        </HamburgerMenu>
      </NavigationAnchorContainer>
      {isMenuShown && (
        <TakeoverMenu>
          <TakeoverMenuCloseButton onClick={() => setIsMenuShown(false)}>
            <Close />
          </TakeoverMenuCloseButton>
          <NavigationAnchorTakeover
            to="/about"
            onClick={() => setIsMenuShown(false)}
          >
            our story
          </NavigationAnchorTakeover>
          <NavigationAnchorTakeover
            to="/clinic"
            onClick={() => setIsMenuShown(false)}
          >
            consultation clinic
          </NavigationAnchorTakeover>
          <NavigationAnchorTakeover
            to="/workshops"
            onClick={() => setIsMenuShown(false)}
          >
            workshops
          </NavigationAnchorTakeover>
          <NavigationAnchorTakeover
            to="/privacypolicy"
            onClick={() => setIsMenuShown(false)}
          >
            privacy policy
          </NavigationAnchorTakeover>
          <NavigationAnchorTakeoverPure
            href="mailto:hello@cradlepsychology.com"
            onClick={() => setIsMenuShown(false)}
          >
            contact
          </NavigationAnchorTakeoverPure>
        </TakeoverMenu>
      )}
    </NavigationContainer>
  )
}
