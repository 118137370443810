import './App.css'
import { Reset } from 'styled-reset'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Footer } from './sections/footer'
import { Hero } from './sections/hero'
import { Problem } from './sections/problem'
import { OurApproach } from './sections/our-approach'
import { MeetTheTeam } from './sections/meet-the-team'
import { LargeRegister } from './sections/large-register'
import { OurStory } from './sections/our-story'
import { FC, Fragment, useEffect } from 'react'
import { PrivacyPolicy } from './sections/privacypolicy'
import { WorkshopUpsell } from './sections/workshop-upsell'
import { Testimonials } from './sections/testimonials'
import { Clinic } from './sections/clinic'
import ReactGA from 'react-ga';
import { AnalyticsRouteTracker } from './common/analytics/analytics'

// Initialize tracking
ReactGA.initialize("UA-274120017-1");

interface IScrollTop {
  children: React.ReactNode
}

const ScrollToTop: FC<IScrollTop> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return <Fragment>{children}</Fragment>;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ScrollToTop>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <Hero />
          <WorkshopUpsell/>
          <Problem />
          <Testimonials />
          <MeetTheTeam />
          <LargeRegister />
          <Footer />
        </>
      </ScrollToTop>
    ),
  },
  {
    path: '/success',
    element: (
      <>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <Hero />
          <Problem />
          <OurApproach />
          <MeetTheTeam />
          <LargeRegister />
          <Footer />
        </>
      </>
    ),
  },
  {
    path: '/privacypolicy',
    element: (
      <>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <PrivacyPolicy />
          <Footer />
        </>
      </>
    ),
  },
  {
    path: '/about',
    element: (
      <ScrollToTop>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <OurStory />
          <LargeRegister />
          <Footer />
        </>
      </ScrollToTop>
    ),
  },
  {
    path: '/workshops',
    element: (
      <ScrollToTop>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <OurApproach />
          <LargeRegister />
          <Footer />
        </>
      </ScrollToTop>
    ),
  },
  {
    path: '/clinic',
    element: (
      <ScrollToTop>
        <Reset />
        <>
          <AnalyticsRouteTracker />
          <Footer />
          <Clinic />
          <LargeRegister />
          <Footer />
        </>
      </ScrollToTop>
    ),
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App
