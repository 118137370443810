const breaks = {
  small: '800px',
  large: '1140px',
}

export const breakPoint = (value: string, size: 'small' | 'large') => {
  return `
    @media (max-width: ${breaks[size]}) {
        ${value}
    }
`
}
