import styled from 'styled-components'
import { breakPoint } from '../../styles'

export const LargeRegisterContainer = styled.div`
  position: relative;
  background: #f7d046;
  padding: 100px;

  ${breakPoint(
    `
    padding: 16px;
  `,
    'small',
  )}
`

export const LargeRegisterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 780px;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    'small',
  )}
`

export const BackgroundImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 0;
`

export const LargeRegisterTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 32px;
  font-weight: bold;

  ${breakPoint(
    `
    font-size: 30px;
    text-align: center;
  `,
    'small',
  )}
`

export const LargeRegisterSubtext = styled.p`
  font-size: 30px;
  margin-bottom: 32px;
  line-height: 42px;
  text-align: center;

  ${breakPoint(
    `
    font-size: 20px;
    line-height: 26px;
  `,
    'small',
  )}
`
