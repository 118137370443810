import styled from 'styled-components'
import { breakPoint } from '../../styles'

export const OurStoryContainer = styled.div`
  padding: 80px 0;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;

  ${breakPoint(
    `
    padding: 32px 16px;
  `,
    'large',
  )}
`

export const OurStoryParagraph = styled.p`
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 20px;
`

export const OurStoryImage = styled.img`
  width: 50%;
  float: left;
  border-radius: 10px;
  margin: 10px 40px 0 0;

  ${breakPoint(
    `
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
  `,
    'small',
  )}
`
